import type { VisitOptions } from '@inertiajs/core'
import { router, usePage } from '@inertiajs/vue3'
import { useTimeoutPoll, useDocumentVisibility, useOnline } from '@vueuse/core'
import { type EffectScope, type UnwrapNestedRefs, computed, effectScope, onScopeDispose, ref, watch } from 'vue'
import { useId } from './useId'

type Keys = NonNullable<VisitOptions['only']>

export const INERTIA_POLLING_RELOAD_HEADER_KEY = 'X-Inertia-Reload'
export const MAX_SAFE_32_BIT_INTEGER = 2147483647 // 2^31 - 1

const subscribers = ref<Array<{ id: number; keys: Keys; interval: number }>>([])
const scope = ref<EffectScope>()
const poll = ref<UnwrapNestedRefs<ReturnType<typeof useTimeoutPoll>>>()

export function useInertiaPolling(keys: Keys, interval = 5000) {
  const id = useId()
  subscribers.value.push({ id, keys, interval })

  function stop() {
    const index = subscribers.value.findIndex((subscriber) => subscriber.id === id)

    if (index !== -1) {
      subscribers.value.splice(index, 1)
    }

    if (scope.value && subscribers.value.length === 0) {
      scope.value.stop()
      scope.value = poll.value = undefined
    }
  }

  if (!poll.value) {
    scope.value = effectScope(true)

    scope.value.run(() => {
      const disposables = ref<Array<() => void>>([])
      const cancelToken = ref<{ cancel: () => void }>()
      const page = usePage()
      const component = computed(() => page.component)
      const resolvedKeys = computed(() => Array.from(new Set(subscribers.value.flatMap(({ keys }) => keys))))
      const online = useOnline()

      const resolvedInterval = computed(() => {
        if (!subscribers.value.length) {
          return MAX_SAFE_32_BIT_INTEGER
        }

        return subscribers.value.reduce((highest, current) => (current.keys.length > highest.keys.length ? current : highest))?.interval
      })

      // @ts-expect-error The return of `useTimeoutPoll` will be unwrapped by the ref
      poll.value = useTimeoutPoll(
        () => {
          return new Promise((resolve) => {
            router.reload({
              headers: {
                [INERTIA_POLLING_RELOAD_HEADER_KEY]: component.value,
              },
              onCancelToken: (token) => {
                cancelToken.value = token
              },
              onFinish: () => {
                cancelToken.value = undefined
                resolve()
              },
              only: resolvedKeys.value,
            })
          })
        },
        resolvedInterval,
        {
          immediate: online.value,
        }
      )

      disposables.value.push(
        watch(online, (value) => {
          if (!value) {
            cancelToken.value?.cancel()
            poll.value?.pause()
          } else if (!poll.value?.isActive) {
            poll.value?.resume()
          }
        })
      )

      disposables.value.push(() => poll.value?.pause())

      disposables.value.push(
        // Cancel the poll request when a new visit is triggered (e.g. navigation, form submission etc.)
        router.on('before', (event) => {
          if (!event.detail.visit.headers[INERTIA_POLLING_RELOAD_HEADER_KEY]) {
            cancelToken.value?.cancel()
            poll.value?.pause()
          }
        })
      )

      disposables.value.push(
        // Resume polling when the non-polling visit is finished
        router.on('finish', (event) => {
          if (!event.detail.visit.headers[INERTIA_POLLING_RELOAD_HEADER_KEY] && !poll.value?.isActive && online.value) {
            poll.value?.resume()
          }
        })
      )

      disposables.value.push(
        // Cancel the poll request when the page component changes
        watch(component, (value, oldValue) => {
          if (oldValue && value !== oldValue && poll.value?.isActive) {
            cancelToken.value?.cancel()
          }
        })
      )

      disposables.value.push(
        // Cancel the poll request when the keys change
        watch(resolvedKeys, (value, oldValue) => {
          if (value.length !== oldValue.length && poll.value?.isActive) {
            cancelToken.value?.cancel()
          }
        })
      )

      disposables.value.push(
        // Cancel the poll request and pause the polling when the page is hidden
        // Resume the polling when the page is visible
        watch(useDocumentVisibility(), (value) => {
          if (value === 'hidden') {
            cancelToken.value?.cancel()
            poll.value?.pause()
          } else if (!poll.value?.isActive && online.value) {
            poll.value?.resume()
          }
        })
      )

      // Remove the disposables when the detached effect scope stops
      onScopeDispose(() => {
        disposables.value.forEach((f) => f())
        disposables.value = []
      })
    })
  }

  // Remove the subscriber when the component is unmounted
  onScopeDispose(() => {
    stop()
  })

  return {
    poll,
    stop,
  }
}
